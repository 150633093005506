.wrapper {
  /* height: 600px; */
  padding-top: 60px;

  position: relative;
  overflow: hidden;
  /* background: #eee; */

  height: 800px;

  @media (--mobile) {
    display: flex;
    flex-direction: column;
    height: unset;
  }

  & :global(.container) {
    height: 100%;

    @media (--mobile) {
      order: 1;
    }
  }
  & :global(.button) {
    @media (--mobile) {
      width: 100%;
    }
  }
}

.layout {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.leftSide {
  display: flex;
  flex: 0 0 50%;
  margin-right: auto;
  flex-direction: column;
  gap: 40px;

  @media (--mobile) {
    flex: 1 1 100%;
    gap: 20px;
  }
}

.rightSide {
  flex: 1 1 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  z-index: 2;

  @media (--mobile) {
    display: none;
  }
}

.items {
  position: absolute;
  width: 50vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
  right: 0;
  z-index: 2;
  height: 100%;
  /* padding: 50px 0; */
  /* order: 2; */

  @media (--mobile) {
    position: relative;
    width: 100vw;
    height: 60vw;
  }
}

.item {
  height: 100%;
  display: flex;
  width: 100%;
  /* height: 300px;
  width: 300px; */

  /* border-radius: 3px; */

  & img {
    height: 100%;
    width: 100%;
    /* border-radius: 3px; */
    object-fit: cover;
  }
}

.io {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-image: linear-gradient(to right, white 10%, transparent 70%);
  left: -1px;

  @media (--mobile) {
    left: unset;
    bottom: -1px;
    background-image: linear-gradient(to top, white 10%, transparent 70%);
  }
}

.background {
  position: absolute;
  top: -100px;
  right: -100px;
  bottom: -100px;
  left: calc(50% + 100px);

  z-index: 1;
  transform: rotate(9deg);

  background-color: #feba00;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%23F26F21' stroke-width='70.8' stroke-opacity='0.28' %3E%3Ccircle fill='%23FEBA00' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%23feb604' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%23fdb208' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%23fcad0c' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%23fca90f' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%23fba511' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%23fba113' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%23fa9c15' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%23f99817' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%23f99419' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%23f88f1a' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%23f78b1b' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%23f6861c' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%23f5821e' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%23f57d1e' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%23f4791f' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%23f37420' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%23F26F21' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  background-position: -100px center;
}

.preTitle {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: -20px;

  @media (--mobile) {
    margin-bottom: -10px;
    font-size: 12px;
  }
}

.title {
  /* color:  var(--primary); */
  font-size: 70px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 500;

  @media (--tabletMax) {
    font-size: 7vw;
  }

  @media (--mobile) {
    font-size: 9vw;
  }
}

.subtitle {
  gap: 10px;
  display: flex;
  flex-direction: column;

  & p {
    color: #999;
    margin: 0;
    padding: 0;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;

    @media (--tabletMax) {
      font-size: 16px;
    }
  }
}
