.wrapper {
  background: linear-gradient(to top left, var(--secondary), var(--primary), var(--primary-600));
  background: var(--primary);
  background: #f7f7f7;

  padding: 100px 0;
  font-family: 'Oswald', sans-serif;
  overflow: hidden;
  position: relative;
}

.background {
  position: absolute;
  top: -100px;
  right: -100px;
  bottom: -100px;
  left: calc(50% + 100px);

  z-index: 1;
  transform: rotate(9deg);

  background-color: #feba00;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%23F26F21' stroke-width='70.8' stroke-opacity='0.28' %3E%3Ccircle fill='%23FEBA00' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%23feb604' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%23fdb208' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%23fcad0c' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%23fca90f' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%23fba511' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%23fba113' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%23fa9c15' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%23f99817' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%23f99419' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%23f88f1a' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%23f78b1b' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%23f6861c' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%23f5821e' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%23f57d1e' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%23f4791f' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%23f37420' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%23F26F21' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  background-position: -100px center;
  
}

.layout {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing4);
  align-items: center;
  margin-right: calc(50%);
}

.content {
  display: flex;
  flex-direction: column;
  text-align: left;
  /* align-items: center; */
  gap: var(--spacing4);

  max-width: 600px;
  margin: 0 auto;
}

.image {
  flex: 1 1 300px;
}

.title {
  /* color: white; */
  /* color: var(--primary); */
  color: #aaa;
  color: rgba(0, 0, 0, 0.2);
  font-size: 40px;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 500;
}

.blurb {
  /* color: white; */
  font-size: 24px;
  line-height: 1.4;
  /* text-transform: uppercase; */
  font-weight: 400;
  /* color: rgba(255, 255, 255, 0.5); */
  gap: var(--spacing2);
  display: flex;
  flex-direction: column;

  & p {
    color: #666;
    margin: 0;
    padding: 0;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
  }
}

.submit {
  display: flex;
  appearance: none;
  background: var(--primary);
  color: white;
  padding: 0 30px;
  height: 50px;
  border: none;
  width: fit-content;
  min-width: 250px;
  place-items: center;
  place-content: center;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 3px;
  /* margin: 0 auto; */
  text-decoration: none;
  /* text-transform:uppercase; */
  transition: 0.2s;

  box-shadow: 0px 2px 0px var(--primary-600), 0px 0px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);

  &:hover,
  &:focus {
    filter: brightness(1.05);

    box-shadow: 0px 3px 0px var(--primary-600), 0px 0px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-3px);
  }

  &:active {
    filter: brightness(1.1);

    box-shadow: 0px 0px 0px var(--primary-600), 0px 0px 0px rgba(0, 0, 0, 0.2);
    transform: translateY(0px);
  }
}
