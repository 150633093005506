.wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
}

.tagsWrapper {
  margin: 50px 0;

  @media (--mobile) {
    margin: 30px 0;
  }
}

.title {
  font-size: 30px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  margin-bottom: 10px;

  @media (--tabletMax) {
    font-size: 20px;
  }
}

.tag {
  height: 30px;
  padding: 0 var(--spacing2);
  background: var(--primary-50);
  color: var(--primary-300);
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 100px;

  display: flex;
  place-content: center;
  place-items: center;
  transition: 0.1s;

  &:hover,
  &:focus {
    transform: translateY(-2px);
    background: var(--primary-50);
    color: var(--primary);
    outline: none;
  }

  @media (--mobile) {
    height: 25px;
    font-size: 12px;
  }
}
