.wrapper {
  z-index: 1;
  position: relative;

  &[data-is-faqs='true'] {
    z-index: 0;
  }

  &[data-is-gallery='true'] {
    z-index: unset;
    position: unset;
  }
}
