.wrapper {
  margin: 50px 0;

  @media (--mobile) {
    margin: 30px 0;
  }
}

.title {
  font-size: 30px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  margin-bottom: 10px;
  
  @media (--tabletMax) {
    font-size: 20px;
  }
}

.promos {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2px;

  > div {
    &:nth-last-child(3) {
      flex: 1 1 30%;
    }

    &:nth-child(even) {
      flex: 1 1 45%;
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      flex: 1 1 30%;
    }
  }
}

.box {
  flex: 1 1 45%;
  margin: 2px;
  position: relative;
}

.boxImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}
