.wrapper {
  margin: 50px 0;

  @media (--mobile) {
    margin: 30px 0;
  }

  &[data-is-inline='true'] {
    margin-top: -20px;

    @media (--mobile) {
      margin-top: -10px;
    }

    &:last-child {
      margin-bottom: -20px;

      @media (--mobile) {
        margin-bottom: -10px;
      }
    }
  }
}

.title {
  font-size: 30px;
  font-weight: 500;
  color: var(--primary);
  color: #ccc;
  text-transform: uppercase;
  margin-bottom: var(--spacing1);

  @media (--tabletMax) {
    font-size: 20px;
  }
}

.layout {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.padder {
  flex: 200px 1 1;
  margin-right: 30px;

  @media (--mobile) {
    display: none;
  }
}

.mosaic {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 3px;

  grid-auto-flow: row dense;

  margin-top: 10px;
  width: 100vw;
  flex: 700px 1 2;
  /* padding: 0 var(--scrollPadding); */

  position: relative;

  &[data-is-inline='true'] {
    margin-top: 0;
  }

  &[data-size='squashed'] {
    & .mosaicImage.mosaicImage {
      max-height: 400px;
    }
  }
  position: relative;

  &[data-size='small'] {
    grid-template-columns: repeat(8, 1fr);

    & .mosaicImage.mosaicImage {
      grid-column: span 2;
      grid-row: span 2;
    }
  }

  &[data-size='smaller'] {
    grid-template-columns: repeat(10, 1fr);

    & .mosaicImage.mosaicImage {
      grid-column: span 2;
      grid-row: span 2;
    }
  }
  &[data-size='raw'] {
    grid-template-columns: repeat(2, 1fr);

    & .mosaicImage.mosaicImage {
      grid-column: span 2;
      grid-row: span 1;

      & img {
        aspect-ratio: auto;
      }
    }
  }
  &[data-size='manual'] {
    --height: 150px;
    display: flex;
    flex-direction: row;

    & .mosaicImage.mosaicImage {

      & img {
        aspect-ratio: initial;
        height: var(--height);
        width:auto;
      }
    }
  }

  &[data-size='equal'] {
    --itemsPerRow: 3;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: center; */

    & .mosaicImage.mosaicImage {
      flex: 0 0
        calc(100% / var(--itemsPerRow) - ((var(--itemsPerRow) - 1) * 3px) / var(--itemsPerRow));

      @media (--mobile) {
        flex: calc(100% / 2 - ((2 - 1) * 10px) / 2);
      }

      & img {
        aspect-ratio: auto;
      }
    }
  }
}

.mosaicImage {
  cursor: pointer;
  grid-column: span 2;
  transition: 0.2s;

  &:nth-child(3n + 1) {
    grid-column: span 4;
    grid-row: span 2;
  }

  &:nth-child(9n + 4) {
    grid-column: span 4;
    grid-column-end: -1;
    grid-row: span 2;
  }

  &:nth-child(9n + 7) {
    grid-column: span 6;
    grid-row: span 1;

    & img {
      aspect-ratio: 20/10;
    }
  }

  &:nth-child(9n + 8),
  &:nth-child(9n + 9) {
    grid-column: span 3;
    grid-row: span 1;
  }

  /* 1 element last child */
  &:nth-child(3n + 1):nth-last-child(1) {
    grid-column: span 6;
    grid-row: span 1;

    & img {
      aspect-ratio: 20/10;
    }
  }

  /* 2 elements last child */
  &:nth-child(3n + 2):nth-last-child(1),
  &:nth-child(3n + 1):nth-last-child(2) {
    grid-row: span 1;
    grid-column: span 3;

    & img {
      aspect-ratio: 3/2;
    }
  }

  /* 2 elements last child */
  &:nth-child(1):nth-last-child(1) {
    grid-row: span 1;
    grid-column: 1 / 7;

    & img {
      aspect-ratio: 20/10;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    /* border-radius: 3px; */
    /* aspect-ratio: 3/2; */
    object-fit: cover;
    object-position: center;
  }

  &:hover {
    filter: brightness(0.5);
  }
}
