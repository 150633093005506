.wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 3px;

  margin: 50px 0;

  @media (--mobile) {
    margin: 30px 0;
  }
}

.panel {
  --width: 25;
  --backgroundColor: var(--primary);
  gap: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-color: var(--backgroundColor);

  flex: 1 1 auto;
  width: calc((var(--width) * 1%) - 10px);
  min-width: 200px;

  &[data-type='image'] {
    user-select: none;
    & .image {
      display: flex;
    }
  }

  &[data-type='stat'] {
    user-select: none;
    padding: 5vh;
    min-width: 300px;

    & .statLabel,
    & .statValue {
      display: flex;
    }
  }

  &[data-type='text'] {
    min-width: 300px;
    padding: 50px;

    & .text {
      display: flex;
    }
  }
}

.image {
  display: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.statLabel {
  display: none;
  color: black;
  font-size: 3vh;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 400;
}

.statValue {
  display: none;
  color: white;
  font-size: 10vh;
  line-height: 1;
  font-weight: 500;
}

.text {
  display: none;
  flex-direction: column;

  font-size: 23px;
  line-height: 1.2;
  color: white;
  gap: var(--spacing1);
  text-align: left;

  & p {
    color: white;
    margin: 0;

    font-size: 2vh;
    font-weight: 400;
    line-height: 1.6;
    font-family: inherit;
  }
}
