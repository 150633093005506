.wrapper {
  display: flex;
  flex-direction: column;
  // flex-direction: row;
}

.title {
  font-size: 30px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  margin-bottom: 10px;

  @media (--mobile) {
    font-size: 20px;
  }
}

.nav {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  // flex-direction: column;
  gap: 2px;
  overflow: auto;

  .navItem {
    background: #fff;
    // background: #f7f7f7;

    padding: 20px 20px;
    // padding-right: 40px;
    line-height: 1.3;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    cursor: pointer;
    border-bottom: 2px solid white;
    white-space: nowrap;

    @media (--mobile) {
      padding: 15px 10px;
      font-size: 14px;
    }

    &[data-is-active='true'] {
      background: #f7f7f7;
      border-bottom: 2px solid #f7f7f7;
      color: var(--primary);
    }
  }
}

.content {
  flex: 1 1 100%;
  background: #f7f7f7;
  // height: 500px;
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;

  @media (--mobile) {
    padding: 5px;
  }

  .images {
    flex: 1 1 100%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    gap: 2px;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s opacity;
    max-height: 0;
    overflow: hidden;

    &[data-is-active='true'] {
      background: #f7f7f7;
      border-right: 2px solid #f7f7f7;
      color: var(--primary);
      opacity: 1;
      pointer-events: all;
      display: flex;
      max-height: unset;

      .image {
        opacity: 1;
        transition-delay: 0.4s;

        &:nth-child(1) {
          transition-delay: 0s;
        }

        &:nth-child(2) {
          transition-delay: 0.1s;
        }

        &:nth-child(3) {
          transition-delay: 0.2s;
        }

        &:nth-child(4) {
          transition-delay: 0.3s;
        }
      }

      .link {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    .image {
      flex: 1 1 calc(50% - 2px);
      aspect-ratio: 2/1;
      position: relative;
      opacity: 0;
      transition: 1s;

      @media (--mobile) {
        aspect-ratio: 4/3;
      }
    }

    .link {
      position: absolute;
      // left: 40px;
      right: 40px;
      bottom: 30px;
      font-size: 22px;
      line-height: 24px;
      font-family: 'Oswald', Arial, sans-serif;
      // border: 1px solid #fff;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;
      padding: 4px 15px;
      min-width: 230px;
      max-width: 265px;
      z-index: 5;
      min-height: 35px;
      transition: 0.3s linear;
      white-space: nowrap;
      cursor: pointer;
      transition: 0.6s;
      transform: translateX(20px);

      @media (--mobile) {
        font-size: 16px;
        min-width: 0;
        max-width: none;
        width: auto;
        display: none;
      }

      i {
        margin-left: 20px;
      }

      &:hover {
        background: var(--primary);
        pointer-events: all;
        transform: translateX(0);
      }
    }
  }

  img {
    object-fit: cover;
    object-position: center;
  }
}
