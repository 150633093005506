.wrapper {
  margin: 50px 0;

  @media (--mobile) {
    margin: 30px 0;
  }
}

.layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
  align-items: stretch;
  border-radius: 3px;
  overflow: hidden;
}

.tile {
  --maxInRow: 2;
  display: flex;
  /* flex: 1 1 400px; */
  flex: 1 1 calc((100% / var(--maxInRow)) - 10px);
  /* min-width: 45%; */
  position: relative;

  --textColor: #808184;
  --backgroundColor: #f7f7f7;

  @media (--mobile) {
    min-height: 200px;
  }
}

.image {
  position: relative;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;

  @media (--mobile) {
    min-height: 300px;
  }

  & img {
    object-fit: cover;
    object-position: center;
  }
}

.text {
  background: #f7f7f7;
  background: var(--backgroundColor);
  padding: var(--spacing4);
  height: 100%;
  min-height: 400px;
  flex: 1 1 auto;

  & * {
    font-family: inherit;
  }

  & p {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.8;

    color: var(--textColor);
  }
}
