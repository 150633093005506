.wrapper {
  display: flex;
  flex-direction: column;
  margin: 50px 0;

  top: -100px;
  padding-top: 100px;
  position: relative;

  margin-bottom: -50px;

  & :global(.container) {
    width: 100%;
  }

  @media (--mobile) {
    margin: 30px 0;
    top: -80px;
    padding-top: 80px;
    margin-bottom: -30px;
  }
}

.title {
  font-size: 30px;
  font-weight: 500;
  color: var(--primary);
  color: #ccc;
  text-transform: uppercase;
  margin-bottom: var(--spacing3);

  @media (--tabletMax) {
    font-size: 20px;
  }
}

.layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.padder {
  flex: 200px 1 1;
  margin-right: 30px;

  @media (--mobile) {
    display: none;
  }
}

.faqs {
  flex: 700px 1 2;
  margin: -10px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: flex-start;

  @media (--mobile) {
    /* flex: unset; */
  }
}

.faq {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  background: #f7f7f7;
  border-radius: 3px;
  padding: 10px 20px;

  flex: 700px 1 2;
  margin: 10px;

  @media (--tabletMax) {
    padding: 10px 20px;
  }

  & + .faq {
    /* margin-top: 20px; */

    /* @media (--tabletMax) {
      margin-top: 10px;
    } */
  }

  & ol {
    margin-top: var(--spacing1);

    & li {
      padding-left: 5px;
      margin-left: -10px;
    }

    & *::marker {
      color: #808184;
      font-weight: 300;
    }
  }

  & i {
    flex: 0 1 50px;
    height: 50px;
    display: flex;
    place-content: center;
    place-items: center;
    font-size: 30px;
    margin-left: -50px;
    /* color: #808184; */
    pointer-events: none;
  }

  &[data-is-open='false'] {
    & .text {
      /* display: none; */
      height: 0;
      overflow: hidden;
    }

    & i {
      transform: rotate(180deg);
    }
  }
}

.question {
  flex: 10 1 100px;

  /* color: #808184; */
  color: #505258;

  font-size: 18px;
  font-weight: 500;
  height: 50px;
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid #e6e6e6; */
  cursor: pointer;
  text-transform: uppercase;
  padding-right: 50px;

  @media (--tabletMax) {
    font-size: 16px;
  }
}

.text {
  /* margin-top: 20px; */
  flex: 1 1 100%;

  @media (--tabletMax) {
    /* margin-top: 10px; */
  }

  & p {
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 17px;

    @media (--tabletMax) {
      font-size: 15px;
      font-weight: 400;
    }
  }
}
