.wrapper {
  margin: 50px 0;

  @media (--mobile) {
    margin: 30px 0;
  }

  & blockquote {
    background: var(--primary);
    padding: 20px;
    color: white;
    margin-top: 20px;

    display: flex;
    flex-flow: row wrap;
    height: fit-content;

    position: relative;

    color: white;
    font-family: 'Oswald', sans-serif;
    border-radius: 3px;

    & p {
      color: white;
      font-family: 'Oswald', sans-serif;
    }

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: var(--primary) transparent transparent transparent;
      transition: 0.1s ease-in-out;
      bottom: -10px;
      right: 10px;
    }
  }

  & h3 {
    font-weight: 400;
    color: #aaa;
    text-transform: uppercase;
    font-size: 26px;

    @media (--mobile) {
      font-size: 20px;
    }
  }

  & h2 {
    font-weight: 500;
    color: #ccc;
    text-transform: uppercase;
    font-size: 30px;

    @media (--mobile) {
      font-size: 20px;
    }

    &[data-is-empty='true'] {
      @media (--mobile) {
        display: none;
      }
    }
  }

  &[data-variant='fullWidth'] {
    margin: 50px 0;

    & .layout {
      display: flex;
      flex-direction: column;
      gap: 10px;

      & .title {
        font-size: 30px;
        font-weight: 500;
        color: #ccc;
        text-transform: uppercase;
        margin-bottom: var(--spacing1);
      }

      & > * {
        flex: 1 1 auto;

        &[data-is-empty='true'] {
          display: none;
        }
      }
    }
  }

  & hr {
    border: none;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
  }

  &[data-variant='grey'] {
    margin: 0px 0;
    padding: 50px 0;

    background: #f7f7f7;

    & hr {
      background: #eee;
    }
  }

  &[data-variant='primary'],
  &[data-variant='secondary'] {
    margin: 50px 0;
    padding: 50px 0;

    background: var(--primary);

    & .title {
      color: white;
    }

    & .text {
      color: white;
      font-family: inherit;

      & * {
        color: white;
        font-family: inherit;
      }

      & p {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.6;
      }
    }
  }

  &[data-variant='alt'] {
    & .title {
    }

    & .text {
      font-family: inherit;

      & * {
        font-family: inherit;
      }

      & p {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.6;
      }
    }
  }

  &[data-variant='secondary'] {
    background: var(--secondary);
  }

  &[data-bottom-spacing='reduced'] {
    margin-bottom: 0px;

    @media (--mobile) {
      margin-bottom: 0px;
    }
  }

  &[data-bottom-spacing='none'] {
    margin-bottom: -40px;

    @media (--mobile) {
      margin-bottom: -20px;
    }
  }

  &[data-top-spacing='reduced'] {
    margin-top: 0px;

    @media (--mobile) {
      margin-top: 0px;
    }
  }

  &[data-top-spacing='none'] {
    margin-top: -40px;

    @media (--mobile) {
      margin-top: -20px;
    }
  }
}

.layout {
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media (--mobile) {
    flex-direction: column;
    gap: 15px;
  }
}

.title.title {
  color: var(--primary);
  font-size: 40px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 500;

  flex: 200px 1 1;

  @media (--mobile) {
    flex: unset;
    font-size: 20px;
  }
}

.text {
  flex: 700px 1 2;

  @media (--mobile) {
    flex: unset;
  }

  & ul {
    /* border: 1px solid black; */
    padding: 20px 40px;
    /* text-transform: uppercase; */
    font-family: Oswald, sans-serif;
    font-weight: 300;
    background: #f7f7f7;

    @nest [data-variant='alt'] &, [data-variant='primary'] &, [data-variant='secondary'] & {
      background: none;
      color: inherit;

      padding: 0px 40px;

      & li {
        &:before {
          color: #333;
        }
      }
    }

    & li {
      margin-top: 5px;

      &:first-child {
        margin-top: 0px;
      }

      &:before {
        color: var(--primary);
        content: '›';
        font-weight: 600;
        margin-top: -2px;
      }
    }
  }
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* gap: 30px; */
  margin: 10px -5px -5px;

  /* @media (--mobile) {
    flex-direction: column;
    gap: 15px;
  } */

  & :global(.button) {
    margin: 5px 5px !important;
    min-width: 200px;

    &:nth-child(even) {
      background: var(--secondary);
    }
  }

  &[data-link-variant='list'] {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-weight: 500;
    gap: 1px;

    & :global(.button) {
      color: white;
      width: min(calc(100% - 25px), 310px);
      border-radius: 0px;
      height: 30px;
      text-align: left;
      justify-content: flex-start;
      padding: 0 15px;
      margin: 0 !important;
      font-size: 14px;
      position: relative; /* Added to position the ::after pseudo-element */

      &:nth-child(even) {
        &:after {
          border-left-color: var(--secondary);
        }
      }
    }

    & :global(.button):after {
      content: ''; /* Creates the arrow */
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 23px solid var(--primary);

      position: absolute;
      top: 50%;
      right: -23px; /* Adjust as needed */
      transform: translateY(-50%);
    }
  }
}

.id {
  visibility: hidden;
  display: block;
  height: 0px;
  margin: -110px 0 0;
  position: absolute;

  /* &:target:before {
    content: "";
  } */
}
