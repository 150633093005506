.wrapper {
  background: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.9);
  color: white;
  backdrop-filter: blur(10px);
  overscroll-behavior: contain;
  overflow: auto;

  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 100;

  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  transition: 0.3s ease-in-out;
  transform: scale(1.1);

  &[data-is-active='true'] {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
    visibility: visible;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  height: 7vh;
  width: 7vh;

  display: flex;
  place-content: center;
  place-items: center;

  cursor: pointer;
}

.image {
  /* width: calc(100vw - 40px);
  max-width: 1000px; */

  width: 100%;
  /* margin: 0 20px; */
  /* margin: 0 20px; */
  aspect-ratio: 3/2;
  max-height: 80vh;

  user-select: none;
  object-fit: cover;
}

.swiper {
  font-size: 100px;
  display: flex;
  flex-direction: row;
  max-width: 100vw;

  & :global(.swiper-slide-next),
  & :global(.swiper-slide-prev) {
    opacity: 0.3;
    cursor: pointer;
  }
}

.swiperSlide {
  &&& {
    /* min-width: 100vw; */
    width: min(1100px, calc(100vw - 80px));
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    font-size: 1.8vh;
    font-weight: 300;
    gap: 2vh;
    transition: opacity 0.4s;
    /* background: white; */
  }
}

.caption {
  height: 4vh;
  opacity: 0;
  transform: translateY(2vh);
  transition: 0.6s;

  @nest :global(.swiper-slide-active) & {
    opacity: 1;
    transform: translateY(0);
  }
}
