.wrapper {
  margin: 50px 0;

  @media (--mobile) {
    margin: 30px 0;
  }
}

.title {
  font-size: 30px;
  font-weight: 500;
  color: var(--primary);
  color: #ccc;
  text-transform: uppercase;
  margin-bottom: var(--spacing1);

  @media (--tabletMax) {
    font-size: 20px;
  }
}

.quotes {
  display: flex;
  flex-flow: row wrap;
  gap: 30px;

  place-content: center;
}

.quote {
  background: var(--primary);
  padding: 20px;
  color: white;

  display: flex;
  flex-flow: row wrap;
  height: fit-content;

  flex: 1 1 400px;
  max-width: 900px;
  position: relative;
  border-radius: 5px;

  @media (--tabletMax) {
    flex: 1 1 300px;
    max-width: unset;
  }

  @media (--mobile) {
    flex: 1 1 100%;
    max-width: unset;
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: var(--primary) transparent transparent transparent;
    transition: 0.1s ease-in-out;
    bottom: -10px;
    right: 10px;
  }

  @media (--mobile) {
    &:nth-child(2n) {
      background: var(--secondary);

      &:after {
        border-color: var(--secondary) transparent transparent transparent;
      }
    }
  }

  @media (--tabletMin) {
    &:nth-child(4n),
    &:nth-child(4n + 1) {
      background: var(--secondary);

      &:after {
        border-color: var(--secondary) transparent transparent transparent;
      }
    }
  }
}

.text {
  font-size: 22px;
  line-height: 1.3;
  flex: 1 1 100%;
  margin-bottom: 10px;
  margin-top: auto;

  @media (--tabletMax) {
    font-size: 18px;
  }
}

.name {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  margin-left: auto;

  @media (--tabletMax) {
    font-size: 12px;
  }
}

.company {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.6;

  @media (--tabletMax) {
    font-size: 12px;
  }
}
