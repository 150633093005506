.wrapper {
  /* height: calc(100vh - 0px); */
  height: var(--maxHeight, calc(100vh - 0px));

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background: black;

  --rows: 3;
  --gap: 0px;
  --imageWidth: calc(150vh / var(--rows));

  &[data-is-started='true'] {
    & .scrollDown {
      cursor: pointer;
      opacity: 1;
      transform: translateY(0);
      /* transition-delay: 3s; */

      & i {
        transition-delay: 3s;
        transform: translateY(0);
      }
    }
  }

  & :global(#glypics-logo) {
    &:global(.animate) {
      animation-delay: 1s;
      animation-duration: 10s;
      animation-name: wave;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      transform-origin: center bottom;
    }
  }
}

/* .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  pointer-events: none;
} */

.logo {
  position: relative;
  z-index: 6;
  margin-top: -10vh;

  & :global(#glypics-logo) {
    height: 25vh;
    opacity: 0;
    transition: 2s;
    transform: scale(0.9) translateY(10vh);
    will-change: transform, opacity;

    & path {
      fill: white;
    }
  }

  &[data-is-active='true'] {
    opacity: 1;

    & :global(#glypics-logo) {
      opacity: 1;
      transform: scale(1);
    }

    & .particles {
      opacity: 0;
      transform: scale(1);
    }
  }

  /* &[data-is-done='true'] {
    opacity: 0;
    transition: 5s;
    transition-delay: 0s;
  } */

  & .particles {
    z-index: 7;
    --animationInterval: calc(var(--animatonTime) / var(--dotCount));
    position: absolute;
    overflow: visible;
    transition: 2s;
    transform: scale(0.9) translateY(10vh);
    opacity: 1;
    will-change: opacity, transform;

    /* &[data-should-animate='true'] .particle { */
    .particle {
      transition: 0.5s linear;
      transition-delay: calc(var(--n) * var(--animationInterval) * 1ms);
      animation-fill-mode: both;
      r: 0.5;
      transform: scale(1);
      opacity: 1;
    }

    & .particle {
      --n: 0;
      fill: white;
      transform: translateX(calc(var(--offsetX) * 1px)) translateY(calc(var(--offsetY) * 1px));
      opacity: 0.01;

      transform-origin: center center;
      will-change: opacity, transform;
    }
  }
}

.title {
  z-index: 10;
  position: relative;
  color: white;
  fill: white;
  height: 10vh;
  font-size: 6vh;
  font-weight: 400;
  opacity: 0;
  will-change: opacity;
  margin-top: 2vh;

  & path {
    fill: white;
  }

  @nest [data-is-started='true'] & {
    transition: 5s;
    transition-delay: 1s;
    opacity: 1;
  }

  /* @nest [data-is-done='true'] & {
    transition: 5s;
    transition-delay: 0s;
    opacity: 0;
  } */
}

.tag {
  z-index: 10;
  position: relative;
  color: white;
  font-size: 3vh;
  font-weight: 300;
  margin-top: 3vh;
  opacity: 0;
  will-change: opacity;

  @nest [data-is-started='true'] & {
    transition: 5s;
    transition-delay: 2s;
    opacity: 1;
  }

  /* @nest [data-is-done='true'] & {
    transition: 5s;
    transition-delay: 0s;
    opacity: 0;
  } */
}

.links {
  z-index: 7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 6vh;
  opacity: 0;
  will-change: opacity;

  @nest [data-is-started='true'] & {
    transition: 5s;
    transition-delay: 3s;
    opacity: 1;
  }

  & .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 1);
    color: black;
    padding: 0 3vh;
    height: 5vh;
    font-size: 2vh;
    position: relative;
    border-radius: 5px;
    transition: 0.1s;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    border-radius: 5px;
    width: 17vh;

    &:nth-child(2) {
      background-color: var(--primary);
      color: white;
      margin-left:2vh;
      /* border-radius: 5px;
      padding-left: 3vh;
      padding-right: 3vh; */

      /* &::after {
        border-color: transparent transparent transparent var(--primary);
        right: calc(-5vh + 1px);
        left: unset;
      } */
    }

    /* &::after {
      content: '';
      position: absolute;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 2.5vh;
      border-color: transparent rgba(0, 0, 0, 0.5) transparent transparent;

      border-color: transparent rgba(255, 255, 255, 1) transparent transparent;

      bottom: 0;
      top: 0;
      left: calc(-5vh + 1px);
      right: unset;
    } */

    &:hover {
      /* transform: scale(1.05); */
      transform: translateY(-2px);
      transform-origin: center bottom;
    }

    &:active {
      transform: scale(1);
      transform-origin: center bottom;
    }
  }
}

.scrollDown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;

  bottom: 4vh;
  left: 50vw;
  width: 60vw;
  margin-left: -30vw;
  color: white;
  gap: 1vh;
  font-size: 2.5vh;
  font-weight: 300;

  z-index: 1;

  transition: 1s;
  transform: translateY(-4vh);
  opacity: 0;

  & i {
    transition: 1s;
    transform: translateY(2vh);
  }
}

.imagesHolder {
}

.images {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  gap: var(--gap);
  background: black;
  /* transform:rotate(-10deg); */
  filter: brightness(0.5);

  opacity: 0.01;

  will-change: opacity;

  &[data-should-animate='true'] {
    animation-delay: calc((var(--animatonTime) + 3000) * 1 * 1ms);
    animation-duration: 5s;
    animation-name: showImages;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: both;
  }

  &[data-is-done='true'] {
    animation-delay: 0s;
    animation-duration: 2.5s;
  }

  /* &[data-show-images='true'] {
    opacity: 1;
    transition: 7s;
  } */
}

.imageRow {
  --imageCount: 0;
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap);
  height: calc((100% - calc((var(--rows) - 1) * var(--gap))) / var(--rows));
  position: relative;
  /* background: white; */
  background: black;
  will-change: transform;

  animation-duration: 200s;
  animation-name: slideAcross;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;

  &:nth-child(2) {
    animation-direction: reverse;
    animation-duration: 300s;
  }

  &:nth-child(3) {
    animation-duration: 400s;
  }
}

.randomImage {
  flex: 0 0 auto;
  position: relative;

  display: flex;
  flex-direction: column;

  height: 100%;
  width: var(--imageWidth);
  overflow: hidden;
  /* visibility: hidden;
  will-change: visibility; */

  & img {
    width: var(--imageWidth);
    min-height: 100%;

    object-fit: cover;
    object-position: center;
    /* transition: 0.5s; */

    /* transform: translateY(calc((var(--currentImageIndex)) * -100%)); */

    will-change: transform;

    animation-delay: calc(var(--interval) * 1ms);
    animation-duration: 12s;
    animation-name: slideVertically;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  &[data-is-in-view='true'] {
    /* visibility: visible; */
  }
}

@keyframes slideAcross {
  0% {
    /* opacity: 0; */
    transform: translateX(0);
  }

  2% {
    opacity: 1;
  }

  98% {
    opacity: 1;
  }

  100% {
    /* opacity: 0; */
    transform: translateX(calc((var(--imageCount) - var(--rows) - 1) * var(--imageWidth) * -1));
  }
}

@keyframes slideVertically {
  0% {
    transform: translateY(0);
  }

  45% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-100%);
  }

  95% {
    transform: translateY(-100%);
  }
}

@keyframes showImages {
  0% {
    opacity: 0.01;
  }

  100% {
    opacity: 1;
  }
}

@keyframes wave {
  2% {
    transform: rotate(-2deg);
  }
  4% {
    transform: rotate(2deg);
  }
  6% {
    transform: rotate(-2deg);
  }
  8% {
    transform: rotate(2deg);
  }
  12% {
    transform: scale(1);
  }
}
