.wrapper {
  position: absolute;
  background: white;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  margin-top: -90px;

  @media (--mobile) {
    margin-top: -50px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 0 10px;
  }

  & a {
    color: var(--primary);
    padding: 0 5px;
  }

  &[data-variant='noBanner'] {
    position: relative;
    margin-left: -25px;
    margin-top: 0;
    margin-bottom: 50px;

    height: auto;
    line-height: 1;

    @media (--mobile) {
      margin-left: -15px;
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
}
