.wrapper {
  margin: 50px 0;

  @media (--mobile) {
    margin: 30px 0;
  }

  & iframe {
    width: 100%;
    /* height: 700px; */
    aspect-ratio: 16/9;
    /* max-width: 1000px; */
    margin: 0 auto;
    display: block;

    @media (--tabletMax) {
      /* height: 50vw; */
    }
  }
}

.title {
  font-size: 30px;
  font-weight: 500;
  color: #ccc;
  text-transform: uppercase;
  margin-bottom: 10px;

  @media (--tabletMax) {
    font-size: 20px;
  }
}
