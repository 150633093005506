.wrapper {
  overscroll-behavior: none auto;
  margin: 50px 0;

  @media (--mobile) {
    margin: 30px 0;
  }
}

.title {
  font-size: 30px;
  font-weight: 500;
  color: var(--primary);
  text-transform: uppercase;

  @media (--tabletMax) {
    font-size: 20px;
  }
}

.images {
  margin-top: 10px;
  width: 100vw;
  padding: 0 var(--scrollPadding);

  position: relative;
  overflow: hidden;

  @media (--mobile) {
    height: 30vh;
  }
}

.placeholder {
  width: 100%;
  aspect-ratio: 20/10;
}

.image {
  position: absolute;
  --relativePosition: 0;
  width: var(--maxWidth);
  aspect-ratio: 20/10;
  user-select: none;
  overflow: hidden;

  opacity: 0;
  z-index: 1;
  opacity: 0;
  transition: 0.3s ease-out;
  will-change: opacity, transform;

  @media (--mobile) {
    aspect-ratio: unset;
    height: 30vh;
  }

  &[data-relative-position='0'] {
    z-index: 10;
    opacity: 1;

    .img {
      filter: blur(0);
    }
    .imageContent {
    }

    .imageTitle {
      transform: translateX(0);
      opacity: 1;
    }
    .imageDescription {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.img {
  width: 100%;
  aspect-ratio: 20/10;
  object-fit: cover;
  object-position: center;
  position: relative;
  transition: 1s;
  filter: blur(10px) brightness(0.8) saturate(200%);
  cursor: pointer;

  @media (--mobile) {
    aspect-ratio: unset;
    height: 30vh;
  }

  img {
    aspect-ratio: 20/10;
    object-fit: cover;
    object-position: center;
    pointer-events: none;
  }
}

.navContainer {
  position:relative;
}

.nav {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: row;
  gap: 2px;
  z-index: 12;

  @media (--mobile) {
    position: relative;
    width: 100%;
    margin-top: 2px;
    gap: 2px;
  }

  .navItem {
    height: 50px;
    width: 50px;
    display: flex;
    place-content: center;
    place-items: center;
    background: rgba(0, 0, 0, 0.4);
    // background: var(--primary);
    color: white;
    font-size: 30px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      // background: rgba(0, 0, 0, 0.6);
    }

    &:active {
      background: var(--primary);
    }

    @media (--mobile) {
      flex: 1 1 auto;
    }
  }
}

.imageContent {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 120px;
  display: flex;
  flex-flow: column;
  gap: 2px;
  z-index: 12;
  place-items: flex-start;
  // opacity: 0;
  transition: 0.2s ease-out;
  // width: 400px;
  // max-width: 100%;

  @media (--mobile) {
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
}

.imageTitle {
  background: rgba(255, 255, 255, 0.95);
  color: black;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;

  transform: translateX(-40px);
  transition: 0.4s ease-out;
  will-change: transform;

  @media (--mobile) {
    text-transform: none;
    font-size: 12px;
    font-weight: 300;
    padding: 5px 10px;
  }
}

.imageDescription {
  background: rgba(255, 255, 255, 0.95);
  color: black;
  padding: 10px 20px;
  font-size: 18px;

  font-weight: 300;
  // font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.3;
  transition: 0.4s ease-out;
  transform: translateY(40px);
  will-change: transform;
  max-width: 600px;

  @media (--mobile) {
    padding: 5px 10px;
    font-size: 12px;
  }
}
